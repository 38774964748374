/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration, OffersApiFp, PartnerApiAxiosParamCreator, PartnerApiFp } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BadRequestException } from '../models';
import { CreateOfferPayloadDto } from '../models';
import { ForbiddenException } from '../models';
import { GetOffersResponseDto } from '../models';
import { NotFoundException } from '../models';
import { OfferDataDto } from '../models';
import { OfferDataWithPatchDto } from '../models';
import { OfferDataWithPersonalDataDto } from '../models';
import { OfferPatchDto } from '../models';
import { OfferPatchResolutionDto } from '../models';
import { OfferStatusPayloadDto } from '../models';
import { UnauthorizedException } from '../models';
import { UpdateOfferPayloadDto } from '../models';
import { UploadImageResponseDto } from '../models';
import { PartnerPosition } from '@/entities/partner-position-entity';
import { OfferPosition } from '@/entities/offer-position-entity';

/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создать предложение
     * @param {CreateOfferPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerCreateOffer: async (body: CreateOfferPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerControllerCreateOffer.');
      }
      const localVarPath = `/api/2.0/market/offer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updatePositionOffer: async (offerPositions: OfferPosition[]): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/offer/positions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions };
      localVarRequestOptions.data = offerPositions

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @summary Удалить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerDeleteOffer: async (offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerDeleteOffer.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffer: async (offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerGetOffer.');
      }
      const localVarPath = `/api/2.0/market/offers/{offer_id}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список предложений
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [category] Категория предложения
     * @param {string} [header] Заголовок предложения
     * @param {number} [id] Уникальный идентификатор предложения
     * @param {string} [patchStatus] Статус обновления предложения
     * @param {string} [status] Статус предложения
     * @param {number} [vendor] Уникальный идентификатор продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffers: async (skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/offers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (header !== undefined) {
        localVarQueryParameter['header'] = header;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (patchStatus !== undefined) {
        localVarQueryParameter['patch_status'] = patchStatus;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (vendor !== undefined) {
        localVarQueryParameter['vendor'] = vendor;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновить предложение
     * @param {UpdateOfferPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOffer: async (body: UpdateOfferPayloadDto, offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerControllerUpdateOffer.');
      }
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerUpdateOffer.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить статус предложения
     * @param {OfferStatusPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOfferStatus: async (body: OfferStatusPayloadDto, offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerControllerUpdateOfferStatus.');
      }
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerUpdateOfferStatus.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}/offer-status`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузить изображение для офера
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUploadImage: async (image: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling offerControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}/image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerApprove: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerApprove.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/approved`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerFindOne: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerFindOne.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerReject: async (body: OfferPatchResolutionDto, offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerPatchControllerReject.');
      }
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerReject.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/rejected`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить отправить обновление предложения на модерацию
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerSend: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerSend.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/pending`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создать предложение
     * @param {CreateOfferPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerCreateOffer(body: CreateOfferPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerCreateOffer(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updatePositionOffer(offerPositions: OfferPosition[]): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .updatePositionOffer(offerPositions);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },

    /**
     *
     * @summary Удалить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerDeleteOffer(offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerDeleteOffer(offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerGetOffer(offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataWithPersonalDataDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerGetOffer(offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список предложений
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [category] Категория предложения
     * @param {string} [header] Заголовок предложения
     * @param {number} [id] Уникальный идентификатор предложения
     * @param {string} [patchStatus] Статус обновления предложения
     * @param {string} [status] Статус предложения
     * @param {number} [vendor] Уникальный идентификатор продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerGetOffers(skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOffersResponseDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerGetOffers(skip, take, category, header, id, patchStatus, status, vendor, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Обновить предложение
     * @param {UpdateOfferPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerUpdateOffer(body: UpdateOfferPayloadDto, offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataWithPatchDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerUpdateOffer(body, offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить статус предложения
     * @param {OfferStatusPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerUpdateOfferStatus(body: OfferStatusPayloadDto, offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataWithPatchDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerUpdateOfferStatus(body, offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Загрузить изображение для офера
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerUploadImage(image: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerControllerUploadImage(image, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerApprove(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerPatchControllerApprove(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerFindOne(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerPatchControllerFindOne(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerPatchControllerReject(body, offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить отправить обновление предложения на модерацию
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerSend(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration)
        .offerPatchControllerSend(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Создать предложение
     * @param {CreateOfferPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerCreateOffer(body: CreateOfferPayloadDto, options?: any): AxiosPromise<OfferDataDto> {
      return OfferApiFp(configuration)
        .offerControllerCreateOffer(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerDeleteOffer(offerId: number, options?: any): AxiosPromise<string> {
      return OfferApiFp(configuration)
        .offerControllerDeleteOffer(offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffer(offerId: number, options?: any): AxiosPromise<OfferDataWithPersonalDataDto> {
      return OfferApiFp(configuration)
        .offerControllerGetOffer(offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список предложений
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [category] Категория предложения
     * @param {string} [header] Заголовок предложения
     * @param {number} [id] Уникальный идентификатор предложения
     * @param {string} [patchStatus] Статус обновления предложения
     * @param {string} [status] Статус предложения
     * @param {number} [vendor] Уникальный идентификатор продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffers(skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options?: any): AxiosPromise<GetOffersResponseDto> {
      return OfferApiFp(configuration)
        .offerControllerGetOffers(skip, take, category, header, id, patchStatus, status, vendor, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновить предложение
     * @param {UpdateOfferPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOffer(body: UpdateOfferPayloadDto, offerId: number, options?: any): AxiosPromise<OfferDataWithPatchDto> {
      return OfferApiFp(configuration)
        .offerControllerUpdateOffer(body, offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить статус предложения
     * @param {OfferStatusPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOfferStatus(body: OfferStatusPayloadDto, offerId: number, options?: any): AxiosPromise<OfferDataWithPatchDto> {
      return OfferApiFp(configuration)
        .offerControllerUpdateOfferStatus(body, offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузить изображение для офера
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUploadImage(image: string, options?: any): AxiosPromise<UploadImageResponseDto> {
      return OfferApiFp(configuration)
        .offerControllerUploadImage(image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerApprove(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return OfferApiFp(configuration)
        .offerPatchControllerApprove(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerFindOne(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return OfferApiFp(configuration)
        .offerPatchControllerFindOne(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return OfferApiFp(configuration)
        .offerPatchControllerReject(body, offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить отправить обновление предложения на модерацию
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerSend(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return OfferApiFp(configuration)
        .offerPatchControllerSend(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
  /**
   *
   * @summary Создать предложение
   * @param {CreateOfferPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerCreateOffer(body: CreateOfferPayloadDto, options?: any) {
    return OfferApiFp(this.configuration)
      .offerControllerCreateOffer(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public updatePositionOffer(offerPositions: OfferPosition[]) {
    return OfferApiFp(this.configuration)
      .updatePositionOffer(offerPositions)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить предложение по id
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerDeleteOffer(offerId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerControllerDeleteOffer(offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить предложение по id
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerGetOffer(offerId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerControllerGetOffer(offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список предложений
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [category] Категория предложения
   * @param {string} [header] Заголовок предложения
   * @param {number} [id] Уникальный идентификатор предложения
   * @param {string} [patchStatus] Статус обновления предложения
   * @param {string} [status] Статус предложения
   * @param {number} [vendor] Уникальный идентификатор продавца
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerGetOffers({
    skip,
    take,
    category,
    header,
    id,
    patchStatus,
    status,
    vendor,
    options
  }: {
    skip?: number,
    take?: number,
    category?: string,
    header?: string,
    id?: number,
    patchStatus?: string,
    status?: string,
    vendor?: number,
    options?: any
  }) {
    return OfferApiFp(this.configuration)
      .offerControllerGetOffers(skip, take, category, header, id, patchStatus, status, vendor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновить предложение
   * @param {UpdateOfferPayloadDto} body
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerUpdateOffer(body: UpdateOfferPayloadDto, offerId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerControllerUpdateOffer(body, offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить статус предложения
   * @param {OfferStatusPayloadDto} body
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerUpdateOfferStatus(body: OfferStatusPayloadDto, offerId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerControllerUpdateOfferStatus(body, offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузить изображение для офера
   * @param {string} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerControllerUploadImage(image: string, options?: any) {
    return OfferApiFp(this.configuration)
      .offerControllerUploadImage(image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подтвердить обновление предложения
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerPatchControllerApprove(offerPatchId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerPatchControllerApprove(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить обновление предложения
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerPatchControllerFindOne(offerPatchId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerPatchControllerFindOne(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отклонить обновление предложения
   * @param {OfferPatchResolutionDto} body
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerPatchControllerReject(body, offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить отправить обновление предложения на модерацию
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferApi
   */
  public offerPatchControllerSend(offerPatchId: number, options?: any) {
    return OfferApiFp(this.configuration)
      .offerPatchControllerSend(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
