<template>
  <q-page>
    <div class="row full-width">
      <q-table
          :columns="columns"
          :rows="store.state.offers.data"
          :rows-per-page-options="[lengthData]"
          class="full-width"
          hide-bottom
          row-key="index"
          title="Расположение поощрений"
          @request="getOffers">
        <template v-slot:top-right>
          <q-btn :disabled="store.state.offers.positions.length === 0" color="accent" icon="done"
                 label="Сохранить расположение"
                 size="sm"
                 @click="savePosition"/>
        </template>
        <template v-slot:body="props">

          <q-tr :id="props.row.position" :props="props" draggable="true" @dragend="droped($event)"
                @dragover="dragover($event, props.row)" @dragstart="dragit($event, props.row)">
            <q-td class="text-center">{{ props.row.position }}</q-td>
            <q-td class="text-center">{{ props.row.offer_id }}</q-td>
            <q-td class="text-left">
              <q-img :src="props.row.image_url" fit="contain" loading="lazy" style="width: 80px; height: 80px;">
              </q-img>
            </q-td>

            <q-td class="text-center">
              <!--              <p>{{ props.row.header }}</p>-->
              <long-text-field :maxlen="100" :value="props.row.header"/>
              <label v-if="props.row.total === 0 || props.row.used === props.row.total" style="color: #C10015">Нет
                доступных купонов, поощрение не показывается в маркете!</label>
            </q-td>
            <!--            <q-td class="text-center">-->
            <!--              <long-text-field :maxlen="30" :value="props.row.comment"/>-->
            <!--            </q-td>-->
            <!--            <q-td class="text-center">{{ props.row.rating }}</q-td>-->
            <!--            <q-td class="text-center">{{$filters.toHumanDate(props.row.date)  }}</q-td>-->
            <q-td class="text-center">
              <q-btn
                  :to="`/organizations/details/${props.row.organization_id}`"
                  dense
                  icon="corporate_fare"
                  round
                  size="sm">
                <q-tooltip>Перейти к партнёру</q-tooltip>
              </q-btn>
              <q-btn
                  :to="`/offers/addedit/${props.row.organization_id}/${props.row.offer_id}`"
                  dense
                  icon="edit"
                  round
                  size="sm">
                <q-tooltip>Редактировать</q-tooltip>
              </q-btn>
              <!--              <q-btn-->
              <!--                  dense-->
              <!--                  icon="delete"-->
              <!--                  round-->
              <!--                  size="sm"-->
              <!--                  @click="deleteOffer(props.row.id)">-->
              <!--                <q-tooltip>Удалить</q-tooltip>-->
              <!--              </q-btn>-->

            </q-td>
          </q-tr>

        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue';
import {OfferApi} from '@/api/apis/offer-api';
import {useStore} from 'vuex';
import LongTextField from '@/components/text/longTextField';
import {Notify} from 'quasar';
import $filters from '../../helpers/filters';

const columns = [
  {
    name: 'position',
    field: 'position',
    align: 'center',
    label: 'Позиция',
  },
  {
    name: 'offer_id',
    field: 'offer_id',
    align: 'center',
    label: 'Id',
  },
  {
    name: 'logo_url',
    field: 'logo_url',
    align: 'left',
    label: 'Иконка предложения',
  },
  {
    name: 'name',
    field: 'name',
    label: 'Название предложения',
    align: 'center',
  },
  {
    name: 'Action',
    label: '',
    field: 'Action',
    sortable: false,
    align: 'left',
  },
];

export default defineComponent({
  methods: {
    path() {
      return path;
    },
  },
  computed: {
    $filters() {
      return $filters;
    },
  },
  components: {LongTextField},
  setup() {
    const store = useStore();
    const moveItem = ref(null);
    const loading = ref(false);
    const lengthData = ref(0);

    async function getOffers() {
      loading.value = true;
      const response = await new OfferApi().offerControllerGetOffers({take: 1000, status: 'approved'});
      store.dispatch('updateOffers', response.data.data);
      // lengthData.value = response.data.data.lenght
      loading.value = false;
    }

    onMounted(() => getOffers());

    const dragit = (event, item) => {
      moveItem.value = item;
    };

    const dragover = (e, item) => {
      const cloneOffers = [...store.state.offers.data];
      const ItemIndex = cloneOffers.indexOf(moveItem.value);
      const woveItemIndex = cloneOffers.indexOf(item);
      cloneOffers.splice(ItemIndex, 1);
      cloneOffers.splice(woveItemIndex, 0, moveItem.value);
      store.dispatch('updateOffers', cloneOffers);
    };
    const droped = () => {
      store.dispatch('updateOffersDropped', store.state.offers.data);
    };

    // const addEditRedirect = (item) => {
    //   store.dispatch('editItemUpdate', item)
    // }

    // async function deleteOffer(id) {
    //   const response = await new OfferApi().deleteOffer(id)
    //   if (response.status === 202) {
    //     store.dispatch('deleteItem', id)
    //   }
    // }

    async function savePosition() {
      const response = await new OfferApi().updatePositionOffer(store.state.offers.positions);
      // const response = await new OfferApi().updatePositionOffer([{a:1}])
      if (response.status === 202) {
        store.dispatch('clearOffersPositions');
        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      }
    }

    return {
      lengthData,
      // addEditRedirect,
      // deleteOffer,
      savePosition,
      dragover,
      dragit,
      droped,
      getOffers,
      columns,
      store,

    };
  },
});
</script>
<style>
.q-btn {
  margin-left: 10px;
}
</style>
