<template>
  <div class="shortText">
    <span v-if="link"
      ><a :href="link">{{ shorted_value }}</a></span
    >
    <span v-else>{{ shorted_value }}</span>

  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  props: ['value', 'maxlen', 'link'],
  setup(props) {
    const shorted_value = computed(() => limitStr(props.value, props.maxlen));
    const value_length = computed(() => (props.value ? props.value.length : 0));
    const showFull = ref(false);
    const limitStr = (str, maxsym) => {
      // console.log(maxsym)
      if (!str) return str;
      if (str.length <= maxsym) return str;
      str = str.substring(0, maxsym).split(' ');

      str = str.map((s) => s.trim());
      str = str.join(' ').trim();

      return `${str}...`;
    };
    return {
      limitStr,
      showFull,
      shorted_value,
      value_length,
    };
  },
});
</script>
<style lang="scss">
.q-tooltip__content {
  opacity: 1 !important;
}
.shortText {
  position: relative;

}
</style>
